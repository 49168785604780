// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__header___YKgi{align-items:center;color:#fff;display:flex;justify-content:flex-end;position:fixed;right:.5em;top:.5em;width:100%;z-index:1}.style__header___YKgi svg{fill:#fff}.style__link__CYpsZ{height:24px;padding:4px;width:24px}", "",{"version":3,"sources":["webpack://./components/Header/style.css"],"names":[],"mappings":"AAAA,sBAKE,kBAAmB,CAKnB,UAAW,CATX,YAAa,CAKb,wBAAyB,CAJzB,cAAe,CAEf,UAAW,CADX,QAAS,CAMT,UAAW,CAFX,SAQF,CAHE,0BACE,SACF,CAGF,oBAGE,WAAY,CAFZ,WAAY,CACZ,UAEF","sourcesContent":[".header {\n  display: flex;\n  position: fixed;\n  top: .5em;\n  right: .5em;\n  align-items: center;\n  justify-content: flex-end;\n  z-index: 1;\n\n  width: 100%;\n  color: #fff;\n\n  svg {\n    fill: #fff;\n  }\n}\n\n.link {\n  padding: 4px;\n  width: 24px;\n  height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "style__header___YKgi",
	"link": "style__link__CYpsZ"
};
export default ___CSS_LOADER_EXPORT___;
